import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { QuestionnaireContextProvider } from '../../context/questionnaire';
import QuestionnaireSliderComponent from '../../components/questionnaire/slider';
import { QuestionnaireType } from '../../entities/questionnaire-type';

export default function QuestionnaireOnboardingPageComponent() {
  let isGift = false;
  const { productId, step } = useParams();
  const [searchParams] = useSearchParams();

  if (searchParams.has('isGift') && searchParams.get('isGift') === 'true') {
    isGift = true;
  }

  const intProductId = parseInt(productId || '', 10);

  return (
    <QuestionnaireContextProvider
      type={step === '2' ? QuestionnaireType.Full : QuestionnaireType.Onboarding}
      productId={intProductId}
      isGift={isGift}
    >
      <QuestionnaireSliderComponent productId={intProductId} />
    </QuestionnaireContextProvider>
  );
}
