export const strings = {
  upTo: 'until',
  redirecting: 'Directing...',
  startingFrom: 'from',
  consultation: '+ consultation',
  written: '+ comment',
  error: {
    required: 'Must agree',
    RequiredFillOut: 'Required to fill out',
    quotaReached: {
      title: 'Sorry, but there are no free trials left today',
      description: 'We invite you to try tomorrow',
    },
    feedback: {
      title: "We're sorry, but an error occurred",
      description: 'Please leave a rating for later',
    },
    common: {
      title: "We're sorry, but an error occurred",
      description: 'Please try again later',
    },
    pageNotFound: {
      title: 'page not found',
      description: 'Check the page address and try again.',
      goHome: 'Return to the beginning',
    },
    fieldIsRequired: 'This field is required',
    youMustSelect: 'Selection is required',
    terms: {
      required: 'Accepting the terms of service is mandatory',
    },
    agreeResearch: {
      required: 'If you do not agree, we will not be able to perform tests and provide you with recommendations',
    },
    agreementForScienceResearch: {
      required: 'If you do not agree, we will not be able to perform tests and provide you with recommendations',
    },
    selectedPatientId: {
      required: 'It is mandatory to select a patient',
    },
    firstName: {
      required: 'Name is required',
    },
    lastName: {
      required: 'Last name is required',
    },
    email: {
      required: 'Email address is required',
      isUsed:
        'In this e-mail A user has already been created for the email address. Please use the login form or use another email. postal address.',
    },
    phone: {
      required: 'Phone is mandatory',
    },
    bank: {
      required: 'Choose a payment method',
    },
    password: {
      minLength: 'Password must be at least {length} characters long',
      same: 'Passwords must match',
    },
    birthDate: {
      required: 'Date of birth is required',
    },
    gender: {
      required: 'Gender is mandatory',
    },
    personCode: {
      required: 'Personal code is mandatory',
      incorrect: 'Enter your personal code. The entered code is incorrect.',
      length: 'Must be eleven characters long',
    },
    agreeRemoteConsultation: {
      required: 'If you do not agree, we will not be able to provide you with remote consultations',
    },
    agreeChildResponsibility: {
      required: 'You must be an authorized person to register a child.',
    },
    agreeTerms: {
      required: 'You must agree to the terms of use',
    },
    YourChildCode: {
      required: 'Personal code is mandatory',
    },
    address: {
      required: 'Specifying the address is mandatory',
    },
    backendError: 'Error occurred. Try again later.',
  },
  loading: {
    wait: 'Wait..',
    wait_until_check: 'Please wait until we receive payment confirmation from the bank',
    wait_until_sign_check: 'Please wait until we receive confirmation of the signing of the document',
  },
  button: {
    close: 'Close',
    continue: 'Continue',
    start: 'Start',
    goBack: 'Come back',
    buy: 'Buy',
    order: 'Order',
    understood: 'Got it',
    pay: 'To pay',
    completedOrder: 'Order',
    check: 'Check',
    login: 'Login',
    preview: 'review',
    createAccount: 'Create an account',
    createPatient: 'Create a patient',
    sendReminder: 'Send a reminder',
    changePassword: 'Change the password',
    signOrder: 'Sign up',
    send: 'send',
    upload: 'Upload',
    complete: 'Finish',
    pdf: 'Download PDF',
    startQuestionnaire: 'Pildyti klausimyną',
    download: 'Atsisiųsti',
  },
  products: {
    CONSULTATION: {
      allergens: '0',
      title: 'Remote consultation of an allergist',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Book a remote consultation',
      description:
        'During the interview, the symptoms and the probability that they are caused by allergies are evaluated. Examinations are selected and an initial treatment plan is drawn up.',
      bullets: [
        "Allergist's assessment and written recommendations",
        'Possibility to ask additional questions',
        '20-minute video call with an allergist',
      ],
    },
    FULL: {
      allergens: '295',
      title: 'Comprehensive allergy testing program',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Order a detailed examination',
      description:
        'The study examines sensitization to nearly 300 allergens, which include virtually all allergens that can cause immediate-type allergic reactions.',
      bullets: [
        'Blood test for 295 allergens (+ up to 10 confirmatory tests)',
        'Assessment and recommendations of an allergist',
        'Detailed description of the allergen profile',
        'Possibility to ask additional questions',
        '20-minute video call with an allergist',
      ],
      review: {
        auto: {
          title: 'Automated survey evaluation',
          price: '+0 €',
          description: 'We will evaluate which allergens are likely to provoke the symptoms you mentioned.',
        },
        written: {
          title: "Doctor's written assessment",
          price: '+29 €',
          description:
            'The allergist will evaluate and comment on the results of your examination, and will prescribe written recommendations.',
        },
        consultation: {
          title: "Doctor's assessment + consultation",
          price: '+49 €',
          description:
            'You will discuss the test results and treatment plan with the allergist during a live remote consultation and also receive a written evaluation.',
        },
      },
    },
    SCREENER: {
      allergens: '{allergensCount}',
      title: 'Primary Allergy Testing Program',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Order an initial examination',
      description:
        'The test examines sensitization to {allergensCount} allergens that cause 95% of immediate-type allergic reactions.',
      bullets: [
        '{allergensCount} allergen blood test',
        "Allergist's assessment and written recommendations",
        'Possibility to ask additional questions',
        '20-minute video call with an allergist',
      ],
    },
    SCREENER_ASIT: {
      allergens: '{allergensCount}',
      title: 'AllergyChip',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Order an AllergyChip test',
      description:
        'The test examines sensitization to {allergensCount} allergens that cause 95% of immediate-type allergic reactions.',
      bullets: [
        '{allergensCount} allergen blood test',
        "Allergist's assessment and written recommendations",
        'Possibility to ask additional questions',
      ],
      review: {
        auto: {
          title: 'Automated survey evaluation',
          price: '+0 €',
          description: 'We will evaluate which allergens are likely to provoke the symptoms you mentioned.',
        },
        written: {
          title: "Doctor's written assessment",
          price: '+29 €',
          description:
            'The allergist will evaluate and comment on the results of your examination, and prescribe written recommendations.',
        },
        consultation: {
          title: "Doctor's evaluation + consultation",
          price: '+49 €',
          description:
            'You will discuss the test results and treatment plan with the allergist during a live remote consultation and also receive a written evaluation.',
        },
      },
    },
    FREE_SCREENER_ASIT: {
      allergens: '66',
      title: 'Primary Allergy Testing Program',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Order an initial examination',
      description:
        'Do you have specific questions about allergies and their treatment? Our doctors will help you answer them.',
      bullets: ["Allergist's assessment and written recommendations", 'Possibility to ask additional questions'],
    },
    FREE_SCREENER: {
      allergens: '28',
      title: 'Primary Allergy Testing Program',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Order an initial examination',
      description:
        'Do you have specific questions about allergies and their treatment? Our doctors will help you answer them.',
      bullets: [
        "Allergist's assessment and written recommendations",
        'Possibility to ask additional questions',
        '20-minute video call with an allergist',
      ],
    },
    IMMUNOTHERAPY_PLAN: {
      allergens: '295',
      title: 'Immunotherapy plan + consultation',
      titleResearch: 'Comprehensive allergy testing to create an immunotherapy plan',
      titleProduct6month: '',
      orderTitle: 'Order a treatment plan',
      description: '..',
      bullets: ['..'],
    },
    TREATMENT_PLAN: {
      allergens: '0',
      title: 'Immunotherapy treatment for 3 months',
      titleResearch: '',
      titleProduct6month: 'Immunotherapy treatment for 6 months',
      orderTitle: 'Book a remote immunotherapy treatment',
      description: '..',
      bullets: ['..'],
    },
    FOOD_ID: {
      allergens: '{allergensCount}',
      title: 'Food intolerance test',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Order a Food Intolerance Test',
      description: 'The study examines sensitization to {allergensCount} food',
      bullets: ['{allergensCount} allergen blood test'],
      chat: {
        heading: 'Do you have questions about your diet or food intolerance?',
      },
      legend: {
        description:
          'A food intolerance test measures the number of IgG-type antibodies in the blood. This type of sensitization is associated with food intolerance. The higher the sensitization, the more likely the food in question can trigger your symptoms. A food intolerance is diagnosed when a test shows hypersensitivity and that food provokes your symptoms.',
      },
    },
  },
  features: {
    ourClinic: 'Allergomedica',
    privateClinic: 'Private clinic',
    visits: {
      title: 'Number of visits',
      hint1: 'For donating blood at the nearest clinic',
      hint2: 'Donate blood and find out the answer',
    },
    timeToResult: {
      title: 'Time to final response from first registration',
      tier1: '7 days',
      tier2: 'from the 10th',
    },
    fullResearch: {
      title: 'Molecular blood test',
    },
    screenerResearch: {
      title: 'Comprehensive allergy blood test',
    },
    detailedProfile: {
      title: 'A detailed profile of your allergy is prepared by an allergist',
    },
    recommendations: {
      title: 'Detailed written recommendations',
    },
    questions: {
      title: 'Possibility to ask new questions later at your convenience',
    },
  },
  product: {
    title: 'Order a detailed study',
    text: 'With the help of our program, you will be able to assess in detail whether the symptoms you are experiencing are caused by an allergen, and which allergens cause these symptoms. We will provide a detailed analysis of allergens, as well as recommendations and treatment methods.',
    recommended: 'We recommend',
    comingSoon: 'Soon',
    select: 'Choose',
  },
  allergo_expert: {
    title: 'Comprehensive diagnostic program for allergies',
    bullets: [
      'Assessment and recommendations of an allergist',
      'Detailed description of the allergen profile',
      'Remote consultation to answer questions',
      'Blood test for 295 allergens (+ up to 10 confirmatory tests)',
      'If you have more questions, you will have the opportunity to ask our team',
    ],
  },
  short_test: {
    scale_text: ["It doesn't bother you", 'Tired easily', 'Moderately tiring', 'Very tiring', 'Extremely tiring'],
  },
  shortTestAnswer: {
    title: 'Thank you for completing the questionnaire.',
    description: {
      research:
        'Based on your answers, the allergist will select the most accurate test methods, and after receiving the results of the tests, he will apply recommendations to you.',
      consultation: 'Your answers will allow the doctor to go deeper into your case before the consultation.',
    },
    weNeedYourAttention: 'We want to draw your attention',
    bookConsultation: 'Book a time for a consultation',
    benefitsTitle: 'What will you learn?',
    benefits: {
      SCREENER: [
        "Which of {allergensCount}'s most popular allergens are you allergic to?",
        'Which allergens are likely to provoke the symptoms you have named, and which are clinically insignificant',
        'How you can improve your quality of life by adjusting your lifestyle',
        'What are your treatment options?',
      ],
      FULL: [
        'Which of the 295 most popular allergens and their molecules are you allergic to?',
        'Which allergens are likely to provoke the symptoms you have named, and which are clinically insignificant',
        'How you can improve your quality of life by adjusting your lifestyle',
        'What are your treatment options, and is immunotherapy right for you?',
      ],
    },
    viewSample: {
      title: 'See what a sample test result looks like',
      button: 'review',
    },
    comparison: {
      title: 'Compare Allergomedica with private clinics',
      included: 'Included',
      notIncluded: 'Not included',
    },
    defaultProductTitle: 'Comprehensive diagnostic program for allergies',
  },
  allergyEvaluation: {
    subscribeAnswer: {
      title: 'Enter your email postal address',
      description:
        'For easy access to the answer to the questionnaire, we will send you the answer link to the email address you provided.',
      buttonText: 'Send',
    },
  },
  shortAnswer: {
    hello: 'Hello,',
    title: 'We have prepared your allergy assessment for you',
    description:
      'The rating is based on the statistics of 2879 people who have already used Allergomedica services. Based on this data, we have evaluated the symptoms you presented and provided an allergy assessment.',
    possibility: {
      title: 'The probability of an acute type of allergy',
      items: {
        veryLow: 'Very low',
        low: 'Low',
        medium: 'Average',
        high: 'High',
        veryHigh: 'Very high',
      },
      hint: 'The evaluation can help determine whether allergy testing is appropriate, but should not be used as a medical diagnosis.',
    },
    allergens: {
      title: 'The most common allergens',
      text: 'These are the allergens that most often cause the symptoms you describe.',
      textNoAllergens: 'The symptoms you mentioned are usually not provoked by rapid-type allergic reactions.',
    },
    moreBlock: {
      title: "What's next?",
      items: {
        low: 'The likelihood that your symptoms are being provoked by an immediate-type allergic reaction is low. We recommend that you contact a doctor in the relevant field in order to assess the origin of the symptoms you are experiencing.',
        high: 'There is a possibility that the symptoms you mentioned are provoked by a rapid type of allergic reaction. To check and have peace of mind, we recommend an initial allergy test.',
        veryHigh:
          'It is very likely that the symptoms you mentioned are provoked by a rapid type of allergic reaction. However, only an allergy test can accurately diagnose this. We recommend a comprehensive or initial allergy test.',
      },
      hint: 'The evaluation can help determine whether allergy testing is appropriate, but should not be used as a medical diagnosis.',
    },
    symptoms: {
      title: 'The symptoms you mentioned',
      noSymptomsIndicated: 'No symptoms indicated',
      noDetails: 'Not named',
      items: {
        often: 'Frequent',
        common: 'Get used to it',
        rare: 'Rarely',
      },
      hint: 'Allergy can manifest itself in various symptoms. Some symptoms are specific and occur mostly during allergies, while others occur more often in cases of other diseases. The specificity of the symptoms helps to determine the risk of allergy.',
    },
    periodicity: {
      title: 'Periodicity',
      hint: 'Recurring symptoms are a common sign of allergic reactions. If you notice periodicity, the probability of allergy is higher.',
    },
    circumstances: {
      title: 'Circumstances',
      hint: 'Rapid-type reactions occur immediately after contact with the allergen, so if you notice the circumstances after which the symptoms appear, it increases the likelihood of an allergy.',
    },
    duration: {
      title: 'Duration of symptoms',
      items: {
        someMonth: 'A few months',
        justNow: 'Just now',
        fiveYear: '5+ years',
      },
      hint: 'If the symptoms appear and repeat for a longer time, the probability that these symptoms are provoked by allergies is higher.',
    },
  },
  test_answer: {
    navigation: {
      allergyProfile: 'Allergy profile',
      recommendations: 'Recommendations',
      allergens: 'All allergens',
    },
    hello: 'Hello,',
    hero_subtitle: 'You have undergone a comprehensive allergy assessment program.',
    hero_text: 'You indicated {num} in the survey You are {tiring} and possibly with allergies {relatedSymptoms}',
    yourAnswerIsNotReady: 'We are glad that you are interested, but your answer is not yet ready.',
    youAreSensitive: 'Sensitization to',
    noSensitivity: 'You have not been sensitized',
    allergenGroupPlural: 'allergen group|allergen groups|allergen groups',
    origin_of_symptoms: 'Origin of symptoms',
    here_we_review_diseases_and_symptoms:
      'Here we will look at what diseases and symptoms allergic reactions can cause.',
    typical_symptoms: 'Typical symptoms',
    allergens: 'Allergens',
    possibly_provoking_symptoms: 'Possible provoking symptoms',
    interpretation_of_sensitivity_intensity: 'Interpretation of sensitization strength',
    no_symptoms: 'Associated symptoms are not listed',
    unclear_origin: 'Unspecified origin',
    doctorPosition: {
      LINAS: 'leading allergist and clinical immunologist',
      ANA: 'Allergist doctor',
      EGLE: 'Allergist doctor',
      AGNE: 'Medical doctor, Mr. resident in allergology and clinical immunology',
      KESTUTIS: 'Allergist doctor',
      LAIMA: 'Allergist and clinical immunologist',
      GABIJA: 'Medical doctor',
    },
    detailedAllergenProfile: {
      title: 'Detailed allergen profile',
      description:
        'Here we will review in detail what possible allergies we discovered from the blood test and explain their connections and importance to you.',
    },
    unidentifiedSymptoms: {
      title: 'Symptoms of unknown origin',
    },
    primaryAllergen: {
      title: 'The main allergen',
      tooltip:
        'An allergen that is considered representative of its group (eg tree pollen) and usually causes allergic reactions. An allergy to this allergen usually develops first.',
    },
    noPrimaryAllergen: 'No major allergen',
    components: {
      title: 'Components',
      onlyInFullResult: 'Individual components of allergens are determined only by detailed molecular allergy testing',
      tooltip:
        'In fact, a person is not sensitized to the entire allergen, but to its constituent parts - components. One allergen usually has several different components, but not all of its components have allergy-causing properties. A person can be sensitized to one or several different components of that allergen. The most common components are proteins. Components are indicated by symbols that consist of the abbreviation of the Latin name of the allergen and a number. For example: the first component of birch (Betula verrucosa) pollen is marked - Bet v 1. The components help to determine which allergens are primary, to predict the risk of certain allergic reactions and help to select a specific treatment.',
    },
    secondaryAllergens: {
      title: 'Other associated allergens',
      onlyInFullResult:
        'We can only identify related allergens and cross-reactions through comprehensive molecular allergy testing',
      tooltip:
        'Allergens that have structural similarities to the main allergen of the group and therefore can also cause allergic symptoms. Most often, sensitivity to these allergens appears later than to the main allergen.',
    },
    relatedSymptoms: {
      title: 'Possible symptoms from these allergens',
      tooltip:
        'Here we list all possible symptoms that can be caused by these allergens. You marked the highlighted symptoms as bothersome in the questionnaire',
    },
    activePeriod: {
      title: 'Active period',
      tooltip: 'The allergens mentioned above usually appear during the period mentioned below.',
      months: 'The months in bold are current',
    },
    viewAllAllergens: 'View all {num} tested allergens',
    viewRecommendations: 'View recommendations',
    symptoms: 'Symptoms',
    tiringPlural: 'frustrating|frustrating|frustrating',
    relatedSymptomsPlural: 'related symptom|related symptoms|related symptoms',
    provokePlural: 'provoked|provoked|provoked',
    symptomsPlural: 'symptom|symptoms|symptoms',
    no_allergens: 'No provoking allergens were found',
    sensitivity: [
      'No sensitization',
      'Mild sensitization',
      'Moderate sensitization',
      'High sensitization',
      'High sensitization',
    ],
    sensitivityShort: ['There is no', 'Easy', 'Average', 'Tall', 'Tall'],
    sensitivitySuffix: ', which can provoke ',
    manySymptoms: 'many symptoms',
    noSymptoms: ', which should not provoke symptoms',
    noSensitivityAllergensListTitle: 'You have been tested for possible sensitization to {num} allergens',
  },
  recommendations: {
    title: 'Recommendations',
    titleActions: 'Start with these recommendations',
    titleAll: 'All recommendations',
    otherRecommendations: 'Other recommendations',
    description:
      'To begin with, we have selected for you several lifestyle and treatment recommendations that can improve the quality of your life. Try them and note if you feel any improvement. Based on your feedback, we will update the recommendations in order to adapt them to you. Please note that the application of some recommendations requires consultation with an allergist.',
    testedGood: 'Incorporate this recommendation into your lifestyle',
    testedBad: 'We will look for other ways to help',
    testedTime: 'Tested on {date}',
    button: {
      work: 'Works',
      notWork: "Doesn't work",
      testedWork: 'Tested',
      testedNotWork: 'Tested',
    },
  },
  textPage: {
    remoteConsultation: {
      title: 'Remote consultation rules',
    },
    privacyPolicy: {
      title: 'privacy policy',
    },
    termsAndConditions: {
      title: 'Terms of service',
    },
    signAgreement: {
      title: 'Signing consent',
    },
  },
  bloodRegistration: {
    title: 'Choose the clinic and time for blood sampling',
    selectClinicStep: 'Choose a clinic',
    pickerPlaceholder: 'Choose...',
    completedTitle: 'Great!',
    completedLocation: 'You have selected {title} ({address}).',
    completedFurtherAction: 'Call the clinic by phone:',
    completeFurtherActionSuffix: 'and make an appointment for a visit.',
    pickUpBoxPlaceholder: '',
  },
  dashboard: {
    orderNewItem: 'Order a new program',
    title: "What's next?",
    noOrders: "It looks like you don't have any orders",
    descriptionNoBloodRegistration: 'Next, you should register at the chosen clinic and donate blood.',
    descriptionNoBlood: 'All you have to do is donate blood and then we can do the test and evaluate its results.',
    tasks: {
      orderCompletedHeading: 'Your result is ready',
      orderCompletedDescription:
        'Our specialists performed a blood test and evaluated its results. You can now view the survey response',
      orderCompletedDescriptionConsultation: 'Our specialists have prepared recommendations for you.',
      incompleteHeading: 'Complete these tasks',
      completedHeading: 'You have completed all tasks!',
      description:
        'After our specialists have completed the investigation, we will inform you by e-mail {email}. You can find the answer to the survey in the "Answer" section above.',
      giftCouponReady: 'Jūsų dovanų kuponas paruoštas',
    },
    bloodRegistration: {
      incompleteTitle: 'Register for a blood draw',
      incompleteDescription:
        'During the blood test, a comprehensive test of 295 allergens and up to 10 corrective tests will be performed ',
      completed: 'Registration for blood collection is successful',
      callClinicByPhone: 'Call the clinic by phone {phone} and arrange an arrival time convenient for you.',
      completedDescription:
        'The blood collection service will not cost you extra. At the clinic, indicate that you have come for a blood collection for the Allergomedica allergy program and show the e-mail confirming the registration. a letter.',
      selectedClinic: 'Your chosen clinic:',
      selectedTime: 'Your registration time:',
      button: 'Register',
    },
    research: {
      completed: 'The questionnaire has been completed successfully',
      description: "Donate blood if you haven't already and we will inform you when the answer is ready",
    },
    orderedAt: 'Ordered:',
    orderId: 'Order number:',
    status: {
      ongoing: 'In progress',
      completed: 'Completed',
    },
    order: 'Order',
    resultPreview: 'View answer',
  },
  form: {
    password: 'Password',
    email: 'email post office',
    surname: 'Surname',
    name: 'Name',
    phone: 'Phone',
    phoneNo: 'Telephone number',
    birthDate: 'date of birth',
    placeholderBirthDate: 'Enter your date of birth',
    birthDateFormat: 'Write the date separated by dashes, for example: 12/08/1999',
    gender: 'Gender',
    genderSelect: 'Select gender',
    genderMale: 'Man',
    genderFemale: 'A woman',
    genderPickerPlaceholder: 'Select gender',
    personCode: 'Personal code',
    newPassword: 'New password',
    repeatPassword: 'Repeat the password',
    YourPersonalIdNo: 'Your personal code',
    YourChildCode: "Your child's personal code",
    spouseName: 'Name of spouse',
    spouseSurname: "Spouse's last name",
    spouseEmail: "Spouse's e-mail address post office",
    address: 'Address',
    city: 'City',
    zipCode: 'Postal code',
    atLeastEightSymbols: 'Minimum 8 characters',
    agreementForScienceResearch:
      'I agree that my personalized information and blood sample will be used for scientific purposes',
    alertSuccessAnswer: {
      title: 'Registration is successful',
      text: 'Thank you! For your convenience, you can find a link to the answer in the specified e-mail. in the post office.',
    },
    alertDangerAnswer: {
      title: 'Registration failed',
      text: 'You have entered an incorrect email. postal address.',
    },
    requiredFields: 'Fields marked with * are mandatory.',
  },
  diForm: {
    title: 'Enter the participant code',
  },
  checkout: {
    success_title: 'Order successful',
    success_text: 'You have received the payment confirmation letter at the specified e-mail address. postal address',
    sign_success_title: 'The document was signed successfully',
    sign_success_text:
      'You have received a letter confirming the signature at the specified e-mail address. postal address',
    titleContactInfo: 'Contact information',
    titleSpouseContactInfo: "Spouse's contact information",
    titleChooseBank: 'Choose a payment method',
    titleInfoForRemoteConsultation: 'Information for remote consultation',
    explanationTextInfoForRemoteConsultation:
      'The personal code is required for prescribing prescription drugs and bringing your consultation to e-health.',
    explanationTextInfoForChildsResearch:
      'Children under 12 years of age require the consent of both parents. We will send a link to the electronic signature of the document to the specified e-mail address. postal address.',
    failed: {
      title: 'Order failed',
      description: 'Your payment could not be received. Please try again or contact us',
    },
    loginTitle: 'Sign in to your account',
    loginDescription:
      'If you have already registered with us before, log in to your account and you will be able to track the status of your application and take further steps.',
    titleYourOrder: 'Your order',
    titleBilling: 'Billing',
    hide: 'Hide',
    iHaveDiscount: 'I have a discount code',
    discount: 'Discount',
    subTotal: 'Sub-total',
    discountCode: 'Discount code',
    amount: 'Amount to be paid',
    checkboxIHaveReadAnd: 'I have read and agree with',
    checkboxPrivacyPolicy: 'privacy policy',
    checkboxAnd: 'and',
    checkBoxTermsOfService: 'Service provision rules',
    checkboxIAgreeThatImunodiagnostika:
      'I agree that Imunodiagnostika, UAB processes my health data in order to create an account, perform tests, and provide health assessment and recommendations.',
    checkboxIAgreeToGetDoctorAdvice:
      "I agree to receive doctor's advice on living with allergies, health news and special offers. You can always easily unsubscribe from news",
    checkboxPayseraText:
      'We inform you that the account information and payment initiation services will be provided by Paysera in accordance with the provision of these services',
    checkboxPayseraTerms: 'rules',
    checkboxPayseraAgreement:
      '. By proceeding with the payment, you confirm that you agree to the provision of these services and the terms of their provision.',
    checkboxAgreeRemoteConsultation: 'I have read and agree with ',
    checkboxAgreeRemoteConsultationSuffix: 'remote consultation rules.',
    checkboxAgreeChildResponsibility:
      'I confirm that I am the authorized person to make health decisions for the child.',
    previewProfile: 'To profile',
    titleResearch: 'Signing consent',
    SigningOfDocumentsDokobit: 'The signing of documents will take place through the Dokobit system.',
    showOrder: '',
    fillQuestionnaire: 'Fill questionnaire',
  },
  registration: {
    title: 'Create an account',
    text: "Once you've created an account, you'll be able to take further steps with your application, and then you'll receive your survey results",
    haveAccount: 'Already have an account?',
    login: 'Log in',
  },
  login: {
    title: 'Log in',
    forgotYourPasswordLink: 'Did you forget your password?',
    dontHaveAccount: 'You do not have an account?',
    register: 'Sign up',
  },
  forgotPassword: {
    requestPasswordReset: 'Password reminder',
    successTitle: 'A password reminder link has been sent to the email address you entered',
    finishPasswordReset: 'Change your password',
    changePasswordSuccessTitle: 'Password updated',
    linkToLogin: 'Return to login',
  },
  selectPatient: {
    title: 'Choose who will be tested:',
    label: 'Choose from the list below or add a new one.',
    labelRadio: 'Select a patient or create a new one',
    addNewPatient: 'Add a new patient',
    button: 'Continue',
  },
  createPatient: {
    title: 'Additional information about the patient',
    label: 'The examination is carried out:',
    addNewPatient: 'Add a new patient',
    button: 'Continue',
    agreementForSienceResearch: 'Continue',
  },
  createPatientChild: {
    title: 'Additional information about your child',
    label: 'The examination is carried out:',
    addNewPatient: 'Add a new patient',
    button: 'Continue',
  },
  navigation: {
    orders: 'Orders',
    result: 'The answer',
    consultations: 'Ask',
    yourProfile: 'My profile',
    signOut: 'disconnect',
    selectItem: 'Select a page',
    comingSoon: 'Soon',
    openMainMenu: 'Open the main menu',
  },
  profileAllergens: {
    weHaveEvaluated: 'We have tested against',
    allergensTotal: ' allergens',
    footnotes: {
      prefix: 'You can find the blood test data source here',
      link: 'here',
      suffix: "(you can see the answer after entering the patient's date of birth)",
    },
    table: {
      allergen: 'Allergen',
      component: 'Component',
      biochemicalDesignation: 'Protein group',
      value: 'Meaning',
      strength: 'Strength',
    },
  },
  legend: {
    title: 'Interpretation of sensitization strength',
    description:
      'The strength of sensitization does not directly indicate whether the reacting allergen will actually cause symptoms. However, the stronger the sensitization, the more likely this finding is clinically significant.',
    senseHigh: 'High sensitization (>3.5 kU/l)',
    senseMedium: 'Moderate sensitization (0.7 - 3.49 kU/l)',
    senseLow: 'Mild sensitization (0.35 - 0.69 kU/l)',
    senseNone: 'No sensitization (0 - 0.34 kU/l)',
    noNum: {
      senseHigh: 'High sensitization',
      senseMedium: 'Moderate sensitization',
      senseLow: 'Mild sensitization',
      senseNone: 'No sensitization',
    },
  },
  order: {
    steps: ['Fill out the questionnaire', 'Pay', 'Give blood', 'Get the answer'],
    stepsConsultation: ['Fill out the questionnaire', 'Pay', 'Call your doctor', 'Get referrals'],
    stepsResearch: ['Fill out the questionnaire', 'Sign the consent', 'Give blood', 'Get the answer'],
    stepsImmunotherapy: ['Fill out the questionnaire', 'Pay', 'Give blood', 'We will make a treatment plan'],
  },
  chat: {
    inputPlaceholder: 'Your message...',
    heading: 'Have questions about your allergies?',
    headingHasDr: 'Questions?',
    description: 'Leave them to our specialists',
    descriptionHasDrPlural: 'Your doctor|Your doctors',
    replyTimeDescription: 'We usually respond within a few hours',
    doctorsTitle: 'Our team of doctors',
    consultation: {
      title: 'Remote consultation',
      description: 'Want to discuss issues during a remote consultation? The first consultation is free.',
      button: 'Register',
    },
  },
  questionnaireConsultationIntro:
    'We guarantee that you will receive the consultation within 2 working days after the payment of the order',
  questionnaireGenericIntro:
    'Fill out the questionnaire and we will advise you which Allergomedica allergy testing program is best for you',
  questionnaireCheckAllergyIntro:
    'Suspect you have allergies? This questionnaire will help you assess the likelihood that your symptoms are caused by an immediate allergic reaction.',
  questionnaireDuration: 'The survey will take up to 5 minutes.',
  doctors: [
    {
      name: 'Linas Griguola',
      nameAccusative: 'Lina Griguolos',
      role: 'leading allergist and clinical immunologist',
      subrole: 'CEO of Allergomedica doctor',
      imageUrl: 'LINAS',
      infoLink: 'lina-griguola',
    },
    {
      name: 'Anna Kozlovska',
      nameAccusative: 'Anna Kozlovska',
      role: 'Allergist and clinical immunologist; pediatric allergist',
      subrole: 'Allergomedica doctor',
      imageUrl: 'ANA',
      infoLink: 'ana-kozlova',
    },
    {
      name: 'Eglė Žilėnaitė',
      role: 'Allergist and clinical immunologist',
      subrole: 'Allergomedica doctor',
      imageUrl: 'EGLE',
      infoLink: 'egle-zilenaite',
    },
    {
      name: 'Kęstutis Černiauskas',
      role: 'Allergist and clinical immunologist',
      subrole: 'Doctor of Allergomedica',
      imageUrl: 'KESTUTIS',
      infoLink: 'kestutis-cerniauskas',
    },
  ],
  reviewsBanner: {
    title: 'How do you rate {companyName}?',
    text: 'We would really appreciate it if you could share your thoughts. It will take you 1-2 minutes.',
    button: 'More',
    dismiss: 'Close',
  },
  consultationBanner: {
    title: 'Want to find a way to reduce or cure your symptoms? Or just consult?',
    text: "Add a doctor's remote consultation for a full examination. During it you will be able to discuss\ntest results and create a treatment plan. You will be able to do this during the ordering process.",
  },
  feedback: {
    success_title: 'Thanks for helping us improve!',
    success_text: 'Your reply has been sent successfully.',
  },
  consultationSuccess: {
    success_title: 'Thank you!',
    success_text: 'Your registration is successful',
  },
  calendar: {
    title: "Doctor's free time",
    freeTime: 'times',
    titleFreeTime: 'Free time - ',
    previousMonth: 'Last month',
    nextMonth: 'Next month',
    noAppointments: 'There are no free times',
    schedule: 'The schedule is for',
    showDates: 'Show dates',
    weekDays: {
      M: 'Mr',
      Tue: 'A',
      W: 'T',
      T: 'K',
      F: 'Mr',
      S: 'Sh',
      Su: 'S',
    },
  },
  immunotherapy: {
    titleTreatment: 'Consent to begin treatment',
    titleAddress: 'Address for drug delivery',
    subTitleAddress: 'At which address do you prefer to receive the medicine?',
    form: {
      terms: {
        firstRow: 'I agree to be treated with ASIT.',
        firstRowChild: 'I consent to ASIT treatment for my child.',
        secondRow: 'I am familiar with ',
        secondRowLinkText: 'treatment protocol.',
        items: [
          'I understand the purpose of the intended treatment, its duration, effectiveness and the possibility of unwanted reactions;',
          'I informed about my diseases and the medicines I take;',
          'I asked concerned questions about this treatment method and received answers;',
        ],
        itemsChild: [
          'I understand the purpose of the intended treatment, its duration, effectiveness and the possibility of unwanted reactions;',
          "I informed about the child's illnesses and medications;",
          'I asked concerned questions about this treatment method and received answers;',
        ],
      },
    },
    successText: 'Survey data saved.',
    treatmentPlan: {
      title: 'Treatment plan',
      titleDuration: 'The review of the treatment plan remains',
      subtitleAllergenPlural: 'Allergen|Allergens',
      subtitleSymptoms: 'Symptoms',
      subtitleDrugsForSymptoms: 'Medicines to relieve symptoms',
    },
    treatmentProgress: {
      title: 'Treatment progress',
      titleSymptoms: 'Severity of symptoms',
      titleProgressBlock: 'General progress',
      titleChart: 'Change in symptom severity over time',
      chartText: 'Treatment progress %',
    },
    treatmentDayPlural: 'day|days|days',
    tabs: {
      ask: 'ask',
      progress: 'Progress',
      plan: 'Plan',
    },
  },
  anamnesis: {
    questionnaire: {
      description:
        'Before your visit, fill out a questionnaire about the symptoms you are experiencing. This will allow us to delve deeper into your situation and make better use of the time allocated to the consultation.',
      submit: {
        title: 'Send the application data',
        privacypolicy1: 'By clicking send, you agree to share data with',
        privacypolicy1IsSecond: 'By clicking finish, you agree to share data with',
        privacypolicy2: 'and agree to',
        privacypolicyLinkTex: 'system privacy policy',
        termsAndConditions1: 'and ',
        termsAndConditionsLinkText: 'rules of use.',
        isSecond: {
          title: 'Thank you, in this case there is no need to fill in the questionnaire',
          title2: 'Your doctor is already familiar with your situation.',
        },
      },
      successTitle: 'Your questionnaire has been successfully sent to the doctor',
      error: 'Failed to send',
      upload: {
        title: 'Upload additional information',
        description: 'Upload additional information that will help the doctor assess your situation faster:',
        list: [' Photographs of skin symptoms experienced.', 'Allergy test results'],
        success: 'Your data has been successfully sent to the doctor',
        noPatientTitle: 'Wrong link.',
        noPatientText: 'Contact your consulting doctor.',
      },
    },
  },
  selectConsultationReview: {
    title: 'Choose how you want to evaluate the results of the study:',
  },
  treatmentProtocol: {
    title: 'Treatment protocol',
  },
  locations: {
    workingHours: 'Working hours:',
  },
  meta: {
    description:
      'The most advanced diagnosis and treatment of allergic diseases. Allergy tests and allergen tests without leaving home. Allergomedica - for a life without allergies.',
    title: 'AllergoMedica - A modern way to understand your allergy.',
    url: 'https://app.allergomedica.lt',
  },
  footer: {
    companyInfo: 'Imunodiagnostika, UAB company code: 303064228',
    address: 'Molėtai str. 16, Goyios Riešės village, LT-14260 Vilnius district.',
    phone: '+370 640 65680',
    email: 'info@allergomedica.lt',
  },
  FOOD_ID: {
    test_answer: {
      navigation: {
        allergyProfile: 'Overview',
        recommendations: 'Recommendations',
        allergens: 'Test results',
      },
      hello: 'Hello,',
      hero_subtitle: 'An overview of your situation',
      hero_text: 'You indicated {num} in the survey You are {tiring} and possibly with allergies {relatedSymptoms}',
      summary: {
        weight: 'Weight',
        kg: 'kg',
        height: 'Height',
        bmi: 'Mass index',
        BMI: 'BMI',
        cm: 'cm',
      },
      youAreSensitive: 'Sensitization to',
      noSensitivity: 'You have not been diagnosed with sensitization to the tested food products.',
      allergenGroupPlural: 'food products was detected.',
      yourSymptoms: 'Your symptoms',
      profileAllergens: {
        weHaveEvaluated: 'In total, we examined sensitization for',
        allergensTotal: 'food products',
        table: {
          allergen: 'Food product',
          strength: 'Class/Strength of Sensitization',
        },
      },
      habits: {
        youAreSensitivePlural: 'Set for you|Set for you|Set for you',
        noHabits: 'You have not been diagnosed with any dietary or lifestyle habits that may provoke symptoms.',
        habitsPlural: 'diet and lifestyle habits|diet and lifestyle habits|diet and lifestyle habits.',
        canProvokeSymptoms: ', which can provoke symptoms.',
        canProvokeYou: 'Can provoke the following symptoms:',
        symptoms: 'symptoms',
        youAreSensitiveStaticTitle:
          'The questionnaire has detected certain habits that may be causing some of your symptoms.',
        canProvokeYouPlural: '',
      },
      symptoms: {
        title: "Let's take a look at the symptoms you mentioned",
        titleHabitsThatCanProvokeSymptom: 'Habits that can provoke this symptom:',
        titleFoodThatCanProvokeSymptom: 'Foods that can provoke this symptom:',
        titleOtherFactors: 'Other factors:',
        factoriesWhich: 'Factors that',
      },
    },
    recommendations: {
      title: 'Recommendations',
      description:
        'Dietary and lifestyle changes have been specially selected for you, which could contribute to the improvement of your well-being.',
      titleProducts: 'Products that we recommend reducing or replacing with others',
      titleProductSymptoms: 'Provoked symptoms:',
      titleHowToAvoid: 'How to avoid it?',
      titleHowToFollowRecommendations: 'How to follow the recommendations?',
      textHowToFollowRecommendations:
        'If the bothersome symptoms do not decrease after applying these recommendations within 3-4 weeks, or alarm symptoms appear during that period, immediately consult a family doctor or a gastroenterologist for a more detailed evaluation.',
    },
    footer: {
      email: 'info@foodid.lt',
    },
    button: {
      showAll: 'Show all',
      showLess: 'Show less',
    },
  },
  years: '{age} years',
  testResults: {
    protocolNumber: 'Protocol number',
    testTitle: 'Tyrimo pavadinimas:',
    nameSurname: 'Vardas ir pavardė:',
    gender: 'Lytis:',
    birthDate: 'Gimimo data:',
    sampleDateTime: 'Ėminio paėmimo data ir laikas:',
    laboratoryDateTime: 'Ėminio gavimo į laboratoriją data ir laikas:',
    testDateTime: 'Tyrimo alikimo data ir laikas:',
    resultsDateTime: 'Rezultatų išdavimo, data ir laikas:',
    sampleTypes: 'Ėminio tipas:',
    sampleCode: 'Mėginio kodas:',
    method: 'Metodas:',
    testPerformedBy: 'Tyrimą atliko:',
    testResultsConfirmed: 'Rezultatus patvirtino:',
  },
  doctorSelection: {
    pickerPlaceholder: 'Choose your doctor ...',
    doNotWantToShare: 'I do not want to share information with the doctor',
  },
};
