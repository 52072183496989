import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

import CreatePatientFormComponent from '../../components/patients/form';
import * as questionnaireApi from '../../services/api/questionnaire';
import CommonAnimatedLoader from '../../components/common/animated/loader';
import { useAuth } from '../../hooks/use-auth';
import { COOKIE_NAME_ANSWER } from '../../context/questionnaire';

function UsersCreatePatientPageComponent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [isChild, setIsChild] = useState<boolean>(false);
  const [age, setAge] = useState<number>(0);
  const [productId, setProductId] = useState<number>();
  const { token, user } = useAuth();
  const navigate = useNavigate();

  const cookies = new Cookies();

  const hash = cookies.get(COOKIE_NAME_ANSWER);

  useEffect(() => {
    const fetchAnswer = async (h: string) => {
      setIsLoading(true);
      const res = await questionnaireApi.answer(h);

      if (res.isGift) {
        navigate(`/payments/checkout/${hash}`);
      }

      setIsChild(res.isChild);

      if (res.name && res.isChild) {
        setFirstName(res.name);
      } else {
        setFirstName(user?.firstName);
      }

      if (!res.isChild) {
        setLastName(user?.lastName);
      }

      if (res.age) {
        setAge(Number(res.age));
      }

      if (res) {
        setProductId(res.product?.id);
      }

      setIsLoading(false);
    };

    if (hash) {
      fetchAnswer(hash);
    }
  }, []);

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto lg:w-4/12">
        <CreatePatientFormComponent
          customerId={user?.id}
          isChild={isChild}
          name={firstName}
          age={age}
          token={token}
          lastName={lastName}
          productId={productId}
        />
      </div>
    </div>
  );
}

export default UsersCreatePatientPageComponent;
