import Cookies from 'universal-cookie';

import { strings as lithuanian } from './locale/lt';
import { strings as polish } from './locale/pl';
import { strings as english } from './locale/en';
import { strings as latvian } from './locale/lv';
import { parametersValues, sharedParameters } from '../constants/parameters';

const cookies = new Cookies();
let currentLocale = 'lt';
let currentDomain = 'foodId';
const currentVersion: string | undefined = cookies.get('v');

export const defaultStrings = lithuanian;

export function setLocale(locale: string) {
  currentLocale = locale;
}

export function setDomain(domain: string) {
  currentDomain = domain;
}

export function strings() {
  let translations: any = lithuanian;

  if (currentLocale === 'pl') {
    translations = polish;
  }
  if (currentLocale === 'en') {
    translations = english;
  }
  if (currentLocale === 'lv') {
    translations = latvian;
  }
  if (currentVersion === '2e0e95285f08a07dea17e7ee111b21c8') {
    translations.footer.companyInfo = '';
    translations.footer.address = 'Allergomedica Polska Ornecka 107, 03-018 Warszawa';
  }

  return translations;
}

export function parameters() {
  return {
    ...sharedParameters,
    ...parametersValues[currentDomain][currentLocale],
  };
}
